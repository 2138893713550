import React from "react";
import "../../style/list.scss";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import DatatablePatients from "./DatatablePatients";

const Patients = () => {
  return (
    <div>
      <DatatablePatients />
    </div>
  );
};

export default Patients;

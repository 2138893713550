import "../../style/list.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DatatableMembers from "./DatatableMembers";

const Members = () => {
  return <DatatableMembers />;
};

export default Members;

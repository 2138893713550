import React from "react";
import axios from "axios";
import "./SureModal.css";
import { useDispatch, useSelector } from 'react-redux';
import { fetchLabOrders, setOrders } from "../../redux/labOrderSlice/LabOrderSlice";

const SureModal = ({
  url,
  config,
  ids,
  setPopupText,
  setPopupshow,
  setPopupColor,
  name,
  setUpClose,
}) => {

  const dispatch = useDispatch();

  const handleDelete = (e) => {
    e.preventDefault();
    try {
      ids.forEach((row) => {
        axios.delete(url + row, config)
        .then((response) => {
          dispatch(setOrders(response.data));
        });
      });
      setUpClose(false);
      setPopupshow(true);
      setPopupText(`${name} deleted`);
      setPopupColor("red");

      setTimeout(() => {
        setPopupshow(false);
        // window.location.reload();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="sure-modal-main">
      <h6>Are you sure?</h6>
      <div>
        <button
          className="workingBtn sure_model_btn"
          style={{ backgroundColor: "green", color: "white" }}
          onClick={handleDelete}
        >
          Yes
        </button>
        <button
          className="workingBtn sure_model_btn"
          style={{ backgroundColor: "red", color: "white" }}
          onClick={() => setUpClose(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SureModal;

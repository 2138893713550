import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../style/datatable.css";

import PopupAlert from "../../components/popupalert/popupAlert";
import { CircularProgress } from "@mui/material";
import { Modal } from "react-bootstrap";
import NewMember from "./NewMember";
import UpdateMember from "./UpdateMember";
import { IoAddCircleSharp } from "react-icons/io5";
import Password from "./Password";

const DatatableMembers = () => {
  const [members, setMembers] = useState([]);
  const [popUpShow, setPopupshow] = useState(false);
  const [popUpText, setPopupText] = useState("");
  const [popUpColor, setPopupColor] = useState("green");
  const [openModal, setOpenModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const token = localStorage.getItem("dsh_token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    axios
      .get(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/members",
        config
      )
      .then((response) => {
        setMembers(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [members]);

  const handleBlock = (id, block) => {
    axios
      .patch(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/members/change/" +
          id,
        { block: block },
        config
      )
      .then((response) => {
        setPopupshow(true);
        if (block) {
          setPopupText("Member Blocked");
          setPopupColor("red");
        } else {
          setPopupText("Member Unblocked");
          setPopupColor("green");
        }
        setTimeout(() => {
          setPopupshow(false);
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else {
          console.log(error);
        }
      });
  };
  const handleDelete = (id) => {
    axios
      .delete(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/members/change/" +
          id,
        config
      )
      .then((response) => {
        setPopupColor("red");
        setPopupshow(true);
        setPopupText("Member Deleted");
        setTimeout(() => {
          setPopupshow(false);
        }, 2000);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else {
          console.log(error);
        }
      });
  };

  const handleDeleteSelectedRows = () => {
    selectedRows.forEach((row) => {
      axios
        .delete(
          "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/members/change/" +
            row,
          config
        )
        .then((response) => {});
    });
    setTimeout(() => {
      setPopupColor("red");
      setPopupshow(true);
      setPopupText(`${selectedRows.length}  Members Deleted`);
      setPopupshow(false);
    }, 2000);
    setSelectedRows([]);
  };

  const actionColumn = [
    {
      field: "username",
      headerName: "Username",
      width: 180,
    },

    {
      field: "name",
      headerName: "name",
      width: 180,
    },
    {
      field: "role",
      headerName: "Role",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
    },

    {
      field: "contact_no",
      headerName: "Contact No",
      width: 180,
    },
    {
      field: "block",
      headerName: "Block",
      width: 180,
      renderCell: (params) => {
        return (
          <select
            value={params.row.block}
            onChange={(e) => handleBlock(params.row._id, e.target.value)}
          >
            <option style={{ color: "red" }} value={true}>
              Blocked
            </option>
            <option style={{ color: "green" }} value={false}>
              Not blocked
            </option>
          </select>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="deleteButton"
              onClick={() => {
                setPasswordModal(true);
                setSelectedRow(params.row);
              }}
            >
              Password
            </div>
            <div
              className="deleteButton"
              onClick={() => {
                setUpdateModal(true);
                setSelectedRow(params.row);
              }}
            >
              Update
            </div>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        {" "}
        Members ({members.length})
        <IoAddCircleSharp
          className="green_logo"
          style={{ cursor: "pointer" }}
          size={40}
          onClick={() => setOpenModal(true)}
        />
      </div>
      {selectedRows.length > 0 ? (
        <button
          onClick={() => {
            handleDeleteSelectedRows();
          }}
        >
          Delete Selected Rows
        </button>
      ) : null}

      <Modal
        style={{ zIndex: 1040 }}
        show={openModal}
        onHide={() => setOpenModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewMember
            setOpenModal={setOpenModal}
            setPopupColor={setPopupColor}
            setPopupText={setPopupText}
            setPopupshow={setPopupshow}
          />
        </Modal.Body>
      </Modal>

      <Modal
        style={{ zIndex: 1040 }}
        show={updateModal}
        onHide={() => setUpdateModal(false)}
      >
        <Modal.Header>
          <Modal.Title>Update Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateMember
            selectedRow={selectedRow}
            setOpenModal={setUpdateModal}
            setPopupColor={setPopupColor}
            setPopupText={setPopupText}
            setPopupshow={setPopupshow}
          />
        </Modal.Body>
      </Modal>
      <Modal
        style={{ zIndex: 1040 }}
        show={passwordModal}
        onHide={() => setPasswordModal(false)}
      >
        <Modal.Header>
          <Modal.Title>Change password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Password
            selectedRow={selectedRow}
            setOpenModal={setPasswordModal}
            setPopupColor={setPopupColor}
            setPopupText={setPopupText}
            setPopupshow={setPopupshow}
          />
        </Modal.Body>
      </Modal>

      {popUpShow ? (
        <PopupAlert popUpText={popUpText} backgroundColor={popUpColor} />
      ) : (
        ""
      )}

      {isLoading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={members}
          columns={actionColumn}
          checkboxSelection={true}
          onSelectionModelChange={(newSelection) => {
            setSelectedRows(newSelection);
          }}
          getRowId={(row) => {
            return row._id;
          }}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      )}
    </div>
  );
};

export default DatatableMembers;

import "../../style/datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import axios from "axios";
import PopupAlert from "../../components/popupalert/popupAlert";
import { CircularProgress } from "@mui/material";

const DatatablePatients = () => {
  const [patients, setpatients] = useState([]);
  const [popUpShow, setPopupshow] = useState(false);
  const [popUpText, setPopupText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [password, setPassword] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedrow, setSelectedRow] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [confirmedModal, setConfirmedModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [all, setAll] = useState(false);

  const token = localStorage.getItem("dsh_token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    axios
      .get("https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/users", config)
      .then((response) => {
        setpatients(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/users/delete/" +
          id,
        config
      )
      .then((response) => {
        console.log(response.data);
        setpatients(patients.filter((el) => el._id !== id));
        setPopupshow(true);
        setPopupText("Patient Deleted");
        setConfirmedModal(false);
        setDeleteId("");

        setTimeout(() => {
          setPopupshow(false);
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.error);
        } else {
          console.log(error);
        }
      });
  };

  const handleDeleteSelectedRows = () => {
    selectedRows.forEach((row) => {
      axios
        .delete(
          "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/users/delete/" +
            row,
          config
        )
        .then((response) => {
          setpatients(response.data);
          setPopupshow(true);
          setAll(false);
          setConfirmedModal(false);
          setPopupText(`${selectedRows.length} Patient Deleted`);
        });
    });
    setTimeout(() => {
      setPopupshow(false);
    }, 2000);
    setSelectedRows([]);
  };

  const serialNoColumn = [
    {
      field: "serial",
      headerName: "S NO.",
      width: 70,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
    },
  ];

  const handleBlock = (id) => {
    axios
      .patch(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/users/block/" + id,
        {
          block: true,
        },
        config
      )
      .then((response) => {
        setpatients(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleUnlock = (id) => {
    axios
      .patch(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/users/block/" + id,
        {
          block: false,
        },
        config
      )
      .then((response) => {
        setpatients(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePassword = () => {
    axios
      .patch(
        `https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/users/update/${selectedrow}`,
        { password: password },
        config
      )
      .then((res) => {
        console.log(res.data);
        setPassword("");
        setOpenAddModal(false);
        setPopupshow(true);
        setPopupText("Password Changed");
      })
      .catch((err) => {
        console.log(err);
      });
    setTimeout(() => {
      setPopupshow(false);
    }, 2000);
  };

  const actionColumn = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "address",
      headerName: "Address",
      width: 200,
      renderCell: (params) => {
        return (
          <div>
            {params.row.address.street}, {params.row.address.city},
            {params.row.address.state}
          </div>
        );
      },
    },
    {
      field: "blocked",
      headerName: "Block",
      width: 200,
      renderCell: (params) => {
        if (params.row.block) {
          return <p style={{ color: "green" }}>Blocked</p>;
        } else {
          return <p style={{ color: "red" }}>Not blocked</p>;
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 500,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              style={{ textDecoration: "none" }}
              onClick={() => {
                setOpenAddModal(true);
                setSelectedRow(params.row._id);
              }}
            >
              <div className="viewButton">Change password</div>
            </div>
            {params.row.block ? (
              <div
                className="deleteButton"
                onClick={() => handleUnlock(params.row._id)}
              >
                Unblock
              </div>
            ) : (
              <div
                className="deleteButton"
                onClick={() => handleBlock(params.row._id)}
              >
                Block
              </div>
            )}
            <div
              className="deleteButton"
              onClick={() => {
                setConfirmedModal(true);
                setDeleteId(params.id);
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">Patients</div>
      {selectedRows.length > 0 ? (
        <button
          onClick={() => {
            setConfirmedModal(true);
            setAll(true);
          }}
        >
          Delete Selected Rows
        </button>
      ) : null}
      {openAddModal ? (
        <div className="modal">
          <div className="modalInner">
            <p
              className="closeModal"
              onClick={() => {
                setOpenAddModal(false);
                setPassword("");
              }}
            >
              X
            </p>
            <div style={{ margin: 40 }}>
              <h3>Change password</h3>
              <input
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="deleteButton" onClick={handleChangePassword}>
                Add
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {confirmedModal ? (
        <div className="modal">
          <div className="modalInner">
            {all ? (
              <div style={{ margin: 40 }}>
                <h5>Are you sure you want to delete all patients?</h5>
                <p style={{ color: "red" }}>
                  Note: Deleting all patients will also delete their:
                </p>
                <ul>
                  <li style={{ color: "red" }}>All Bookings</li>
                  <li style={{ color: "red" }}>All Lab Orders</li>
                  <li style={{ color: "red" }}>All Pharmacy Orders</li>
                </ul>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <button
                    className="viewButton"
                    style={{ width: 150 }}
                    onClick={() => {
                      setConfirmedModal(false);
                      setAll(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="viewButton"
                    style={{ width: 150 }}
                    onClick={() => handleDeleteSelectedRows()}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            ) : (
              <div style={{ margin: 40 }}>
                <h5>Are you sure you want to delete this patient?</h5>
                <p style={{ color: "red" }}>
                  Note: Deleting patient will also delete his/her:
                </p>
                <ul>
                  <li style={{ color: "red" }}>All Bookings</li>
                  <li style={{ color: "red" }}>All Lab Orders</li>
                  <li style={{ color: "red" }}>All Pharmacy Orders</li>
                </ul>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <button
                    className="viewButton"
                    style={{ width: 150 }}
                    onClick={() => {
                      setConfirmedModal(false);
                      setDeleteId("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="viewButton"
                    style={{ width: 150 }}
                    onClick={() => handleDelete(deleteId)}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {popUpShow ? (
        popUpText === "Password Change" ? (
          <PopupAlert popUpText={popUpText} backgroundColor={"#8AFF8A"} />
        ) : (
          <PopupAlert popUpText={popUpText} backgroundColor={"red"} />
        )
      ) : (
        ""
      )}
      {isLoading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={patients}
          columns={serialNoColumn.concat(actionColumn)}
          checkboxSelection={true}
          onSelectionModelChange={(newSelection) => {
            setSelectedRows(newSelection);
          }}
          getRowId={(row) => {
            return row._id;
          }}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      )}
    </div>
  );
};

export default DatatablePatients;

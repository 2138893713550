// store.js

import { configureStore } from '@reduxjs/toolkit';
import pharmacyReducer from './pharmacySlice/PharmacySlice';
import labOrdersReducer from './labOrderSlice/LabOrderSlice';
import bookingReducer from './bookingSlice/BookingSlice';

const store = configureStore({
  reducer: {
    pharmacy: pharmacyReducer,
    labOrders: labOrdersReducer,
    booking: bookingReducer
  },
});

export default store;

import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ReviewsIcon from "@mui/icons-material/Reviews";
import MedicationIcon from "@mui/icons-material/Medication";
import LogoutIcon from "@mui/icons-material/Logout";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import UpdateIcon from "@mui/icons-material/Update";

import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchBookingData } from "../../redux/bookingSlice/BookingSlice";
import { fetchPharmacyOrders } from "../../redux/pharmacySlice/PharmacySlice";
import { fetchLabOrders, fetchLabTests } from "../../redux/labOrderSlice/LabOrderSlice";

const Sidebar = () => {
  const [newPharamcyOrders, setNewPharmacyOrders] = useState(0);
  const [newLabOrders, setNewLabOrders] = useState(0);
  // const [newAppointmentsOrders, setNewAppointments] = useState(0);

  const token = localStorage.getItem("dsh_token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const dispatch = useDispatch();
  const { bookingData } = useSelector(state => state.booking);
  const { orders } = useSelector((state) => state.pharmacy);
  const { orders: labOrders } = useSelector((state) => state.labOrders);


  useEffect(() => {
    dispatch(fetchBookingData());
    dispatch(fetchPharmacyOrders({ config }));
    dispatch(fetchLabOrders({ config }));
  }, [dispatch]);

  const [data, setData] = useState("");
  const navigate = useNavigate();
  const role = localStorage.getItem("dsh_role");
  const dsh_Id = localStorage.getItem("dsh_Id");


  useEffect(() => {
    axios
      .get(
        `https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/members/change/${dsh_Id}`,
        config
      )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dsh_Id]);

  useEffect(() => {
    // axios
    //   .get(
    //     "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/lab-orders/status/Pending",
    //     config
    //   )
    //   .then((response) => {
    //     setNewLabOrders(response.data.length);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // axios
    //   .get(
    //     "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/pharmacy-orders",
    //     config
    //   )
    //   .then((response) => {
    //     const pending = response.data.filter(
    //       (labOrder) => labOrder.status === "Pending"
    //     );
    //     setNewPharmacyOrders(pending.length);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // axios
    //   .get("http://119.63.129.51:8000/api/Appointmentdata", config)
    //   .then((response) => {
    //     setNewAppointments(response.data?.Appointmentdatas?.length);
    //   })
    //   .catch((error) => {});

    const pendingLabs = labOrders.filter(
      (labOrder) => labOrder.status === "Pending"
    );
    setNewLabOrders(pendingLabs.length);

    const pending = orders.filter(
      (pharmacyOrder) => pharmacyOrder.status === "Pending"
    );
    setNewPharmacyOrders(pending.length);

  }, [orders, labOrders]);

  const handleLogout = () => {
    localStorage.removeItem("loggedI_DSH");
    localStorage.removeItem("dsh_role");
    localStorage.removeItem("dsh_Id");
    localStorage.removeItem("dsh_token");
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="sidebar">
      <div className="top">
        <Link
          to="/dashboard"
          style={{
            textDecoration: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span className="logo">Darul Sehat</span>
          <span style={{ color: "black", fontSize: 15 }}>
            {data?.name} ({data?.role})
          </span>
        </Link>
      </div>
      <hr className="break-sidebar" />
      <div className="center">
        <ul>
          {role === "admin" ? (
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <li>
                <DashboardIcon className="icon" />
                <span>Dashboard</span>
              </li>
            </Link>
          ) : (
            ""
          )}
          {role === "admin" ? (
            <Link to="/members" style={{ textDecoration: "none" }}>
              <li>
                <PersonOutlineIcon className="icon" />
                <span>Members</span>
              </li>
            </Link>
          ) : (
            ""
          )}
          {role === "admin" ? (
            <Link to="/patients" style={{ textDecoration: "none" }}>
              <li>
                <PersonOutlineIcon className="icon" />
                <span>Patients</span>
              </li>
            </Link>
          ) : (
            ""
          )}
          {role === "admin" && (
            <Link to="/doctors" style={{ textDecoration: "none" }}>
              <li>
                <PersonOutlineIcon className="icon" />
                <span>Doctors</span>
              </li>
            </Link>
          )}

          {role === "admin" || role === "pharmacy" ? (
            <>
              <Link to="/pharmacy-orders" style={{ textDecoration: "none" }}>
                <li>
                  <MedicationIcon className="icon" />
                  <span>Pharmacy Orders</span>
                  <span
                    style={{
                      color: "white",
                      textAlign: "center",
                      backgroundColor: "black",
                      paddingLeft: 4,
                      paddingRight: 4,
                      borderRadius: 10,
                    }}
                  >
                    {newPharamcyOrders}
                  </span>
                </li>
              </Link>
              <Link to="/medicines" style={{ textDecoration: "none" }}>
                <li>
                  <MedicationIcon className="icon" />
                  <span>Medicines</span>
                  {/* <span
                    style={{
                      color: "white",
                      textAlign: "center",
                      backgroundColor: "black",
                      paddingLeft: 4,
                      paddingRight: 4,
                      borderRadius: 10,
                    }}
                  >
                    {newPharamcyOrders}
                  </span> */}
                </li>
              </Link>
            </>
          ) : (
            ""
          )}
          {role === "admin" || role === "lab" ? (
            <>
              <Link to="/lab-orders" style={{ textDecoration: "none" }}>
                <li>
                  <MedicationIcon className="icon" />
                  <span>Lab Orders</span>
                  <span
                    style={{
                      color: "white",
                      textAlign: "center",
                      backgroundColor: "black",
                      paddingLeft: 4,
                      paddingRight: 4,
                      borderRadius: 10,
                    }}
                  >
                    {newLabOrders}
                  </span>
                </li>
              </Link>

              <Link to="/lab-tests" style={{ textDecoration: "none" }}>
                <li>
                  <MedicationIcon className="icon" />
                  <span>Lab Tests</span>
                  {/* <span
                    style={{
                      color: "white",
                      textAlign: "center",
                      backgroundColor: "black",
                      paddingLeft: 4,
                      paddingRight: 4,
                      borderRadius: 10,
                    }}
                  >
                    {newLabOrders}
                  </span> */}
                </li>
              </Link>
            </>
          ) : (
            ""
          )}

          {role === "admin" ? (
            <Link to="/appointments" style={{ textDecoration: "none" }}>
              <li>
                <BookOnlineIcon className="icon" />
                <span>Appointments</span>
                <span
                  style={{
                    color: "white",
                    textAlign: "center",
                    backgroundColor: "black",
                    paddingLeft: 4,
                    paddingRight: 4,
                    borderRadius: 10,
                  }}
                >
                  {bookingData?.length || 0}
                </span>
              </li>
            </Link>
          ) : (
            ""
          )}
          {role === "admin" ? (
            <Link to="/delete-requests" style={{ textDecoration: "none" }}>
              <li>
                <PersonOutlineIcon className="icon" />
                <span>Account Delete Requests</span>
              </li>
            </Link>
          ) : (
            ""
          )}
          <Link to="/update" style={{ textDecoration: "none" }}>
            <li>
              <UpdateIcon className="icon" />
              <span>Update Credentials</span>
            </li>
          </Link>
          <Link
            to="/"
            style={{ textDecoration: "none" }}
            onClick={handleLogout}
          >
            <li>
              <LogoutIcon className="icon" />
              <span>Logout</span>
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;

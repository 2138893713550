import "../../style/datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const DatatableMedicines = () => {
  const [doctors, setDoctors] = useState([]);

  const { medicines, loading } = useSelector((state) => state.pharmacy);
  // useEffect(() => {
  //   axios
  //     .get("http://119.63.129.51:8000/api/Physicianprofile")
  //     .then((response) => {
  //       if (response.data) {
  //         setDoctors(response.data.physicians);
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       console.log(error);
  //     });
  // }, [doctors]);

  const actionColumn = [
    {
      field: "value",
      headerName: "Value",
      width: 100,
    },
    {
      field: "label",
      headerName: "Name",
      width: 300,
    },
    {
      field: "group_idesc",
      headerName: "Group Description",
      width: 200,
    },
    {
      field: "generic_desc",
      headerName: "Generic Description",
      width: 300,
    },
    {
      field: "unit_price",
      headerName: "Unit Price",
      width: 100,
    },
    {
      field: "pack_nos",
      headerName: "Stock",
      width: 100,
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 200,
      valueGetter: (params) => {
        return params.row.balance ? params.row.balance : "-";
      },
    },
    {
      field: "prescription",
      headerName: "Prescription",
      width: 150,
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">Medicines</div>

      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={medicines}
          columns={actionColumn}
          getRowId={(row, index) => {
            return row.value;
          }}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      )}
    </div>
  );
};

export default DatatableMedicines;

import React, { useState, useEffect } from "react";
import "./login.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent page reload

    var { username, pass } = document.forms[0];

    axios
      .post(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/members/login",
        {
          username: username.value,
          password: pass.value,
        },
        { withCredentials: true }
      ) // send token in cookie)
      .then((response) => {
        setIsSubmitted(true);
        localStorage.setItem("loggedI_DSH", true);
        localStorage.setItem("dsh_Id", response.data.user);
        localStorage.setItem("dsh_token", response.data.token);
        localStorage.setItem("dsh_role", response.data.role);
        const role = response.data.role;
        if (role === "admin") {
          navigate("/dashboard");
        } else if (role === "lab") {
          navigate("/lab-orders");
        } else {
          navigate("/pharmacy-orders");
        }
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.token}`;
        window.location.reload();
      })
      .catch((error) => {
        setErrorMessage(error.response.data);
        setIsSubmitted(false);
        console.log(error);
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // JSX code for login form
  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          {isSubmitted ? (
            ""
          ) : (
            <div style={{ color: "red", fontSize: 15 }}>{errorMessage}</div>
          )}
          <label>Username </label>
          <input type="text" name="username" required />
        </div>
        <div className="input-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label>Password </label>
            <div
              onClick={togglePasswordVisibility}
              style={{ cursor: "pointer" }}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
          <input
            type={showPassword ? "text" : "password"}
            name="pass"
            required
          />
        </div>
        <div className="button-container">
          <input type="submit" />
        </div>
      </form>
    </div>
  );

  return (
    <div className="login">
      <div className="login-form">
        <div className="title">Darul Sehat Dashboard</div>
        {renderForm}
      </div>
    </div>
  );
};

export default Login;

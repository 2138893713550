import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import DatatableUsersRequests from "./DatatableUsersRequests";

const UsersRequests = () => {
  return <DatatableUsersRequests />;
};

export default UsersRequests;

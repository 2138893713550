import React from "react";
import App from "./App";
import { DarkModeContextProvider } from "./context/darkModeContext";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom"; // Import the Router component
import { Provider } from 'react-redux';
import store from './redux/store.js';

ReactDOM.render(
  <Provider store={store}>
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
  </Provider>, document.getElementById("root")
);

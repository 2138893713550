import React, { useState } from "react";
import ImageZoom from "react-image-zooom";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchLabOrders,
  setOrders,
} from "../../redux/labOrderSlice/LabOrderSlice";

const ViewDetails = ({
  selectedRow,
  setPopupColor,
  setPopupText,
  setPopupshow,
  setOpenModal,
  config,
}) => {
  const [file, setFile] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleFileInputChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = (rn) => {
    const formData = new FormData();
    formData.append("result", file);
    formData.append("name", rn);

    axios
      .patch(
        `https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/lab-orders/completed/${selectedRow._id}`,
        formData,
        config
      )
      .then((response) => {
        setPopupText("Result Uploaded");
        setPopupColor("green");
        setPopupshow(true);
        dispatch(fetchLabOrders({ config }));
        setTimeout(() => {
          // window.location.reload();
          setOpenModal(false);
          setPopupshow(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div style={{ margin: 40 }}>
      <h1>Order Details</h1>
      <h3>Patient Details</h3>

      {selectedRow.patientDetails ? (
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td style={{ fontWeight: "bold" }}>Patient name</td>
              <td>{selectedRow.patientDetails?.name}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Contact No</td>
              <td>{selectedRow.user?.phoneNo}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Gender</td>
              <td>{selectedRow.patientDetails?.gender}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Age</td>
              <td>{selectedRow.patientDetails?.age}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Address</td>
              <td>{selectedRow.full_address}</td>
            </tr>
          </tbody>
        </Table>
      ) : (
        ""
      )}

      <h3>Order Details</h3>
      <Table striped bordered hover>
        <tbody>
          <tr>
            <td style={{ fontWeight: "bold" }}>Status</td>
            <td>{selectedRow.status}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>Total Price</td>
            <td>{selectedRow.totalPrice}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>Payment Mode</td>
            <td>{selectedRow.payment_mode}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>Basket ID</td>
            <td>{selectedRow.basket_id ? selectedRow.basket_id : "-"}</td>
          </tr>
        </tbody>
      </Table>

      {selectedRow.tests?.length > 0 ? (
        <div>
          <h3>Tests: </h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Test Code</th>
                <th>Test Group Code</th>
                <th>Test Name</th>
                <th>Test Rate</th>
                <th>Due date</th>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              {selectedRow.tests.map((row, index) => (
                <tr key={index}>
                  <td>{row.main_serv_code}</td>
                  <td>{row.group_serv_code}</td>
                  <td style={{ fontWeight: "bold", color: "black" }}>
                    {row.group_serv_desc}
                  </td>
                  <td>{row.private_rate}</td>
                  <td>{row.due_date}</td>
                  <td>
                    {row.result ? (
                      <a
                        href={`https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/lab-orders/docs/${row.result}`}
                        target="_blank"
                      >
                        Open
                      </a>
                    ) : (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleUpload(row.rn);
                        }}
                      >
                        <input
                          type="file"
                          onChange={handleFileInputChange}
                          accept=".pdf"
                        />
                        (PDF)
                        <button type="submit">Upload</button>
                      </form>
                    )}
                  </td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td></td>
                <td>Total</td>
                <td style={{ fontWeight: "bold", color: "black" }}>
                  {selectedRow.totalPrice}
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </div>
      ) : (
        ""
      )}
      {selectedRow.images?.length > 0 ? (
        <div>
          <h3>Prescription Images: </h3>
          {selectedRow.images.map((row, index) => (
            <div
              key={index} // Make sure to add a unique key to each element in the array
              onClick={() =>
                window.open(
                  `https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/lab-orders/docs/${row}`,
                  "_blank"
                )
              }
            >
              <ImageZoom
                src={`https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/lab-orders/docs/${row}`}
                alt={row}
                zoom="200"
                width="500"
                height="500"
                className="zoomImage"
              />
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ViewDetails;

import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import DatatableDoctors from "./DatatableDoctors";

const Doctors = () => {
  return <DatatableDoctors />;
};

export default Doctors;

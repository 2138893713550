import "../../style/new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../style/datatable.css";
import { useEffect, useState } from "react";
import axios from "axios";
import PopupAlert from "../../components/popupalert/popupAlert";

const UpdateAdmin = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [popUpShow, setPopupshow] = useState(false);
  const [popUpText, setPopupText] = useState("");
  const dsh_Id = localStorage.getItem("dsh_Id");

  const token = localStorage.getItem("dsh_token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    axios
      .patch(
        `https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/members/change/${dsh_Id}`,
        {
          password: password,
          oldPassword: oldPassword,
        },
        config
      )
      .then((response) => {
        console.log(response.data);
        setPopupshow(true);
        setPopupText("Password updated");
        setTimeout(() => {
          setPopupshow(false);
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data) {
          alert(error.response.data);
        }
      });
  };

  return (
    <div>
      {popUpShow ? (
        <PopupAlert popUpText={popUpText} backgroundColor={"orange"} />
      ) : (
        ""
      )}
      <div className="datatableTitle" style={{ marginLeft: 20 }}>
        Update Password
      </div>
      <div className="bottom">
        <div className="right">
          <form className="form-new" onSubmit={handleUpdate}>
            <div
              className="formInput"
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "0px 20px",
                width: "50%",
              }}
            >
              <label className="label-form">Current Password</label>
              <input
                className="input-form"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
              <label className="label-form">New Password</label>
              <input
                className="input-form"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />

              <button className="createButton">Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateAdmin;

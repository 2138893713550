import "../../style/datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import PopupAlert from "../../components/popupalert/popupAlert";
import { CircularProgress, Rating } from "@mui/material";

const DatatableReviews = () => {
  const [reviews, setreviews] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popUpShow, setPopupshow] = useState(false);
  const [popUpText, setPopupText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const token = localStorage.getItem("dsh_token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    axios
      .get(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/reviews",
        config
      )
      .then((response) => {
        setreviews(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/reviews/" + id,
        config
      )
      .then((response) => {
        console.log(response.data);
        setreviews(reviews.filter((el) => el._id !== id));
        setPopupshow(true);
        setPopupText("Review Deleted");
        setTimeout(() => {
          setPopupshow(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteSelectedRows = () => {
    selectedRows.forEach((row) => {
      axios
        .delete(
          "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/reviews/" + row,
          config
        )
        .then((response) => {
          setreviews(response.data);
          setPopupshow(true);
          setPopupText(`${selectedRows.length} Reviews Deleted`);
        });
    });
    setTimeout(() => {
      setPopupshow(false);
    }, 2000);
    setSelectedRows([]);
  };

  const actionColumn = [
    {
      field: "doctorName",
      headerName: "Doctor",
      width: 200,
      renderCell: (params) => {
        return (
          <p style={{ color: "black" }}>{params.row.doctorDetails.name}</p>
        );
      },
    },
    {
      field: "userName",
      headerName: "User",
      width: 200,
      renderCell: (params) => {
        return <p style={{ color: "black" }}>{params.row.userDetails.name}</p>;
      },
    },
    {
      field: "review",
      headerName: "Review",
      width: 200,
    },
    {
      field: "rating",
      headerName: "Review",
      width: 200,
      renderCell: (params) => {
        return <Rating value={params.row.rating} precision={0.5} readOnly />;
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/reviews/update/${params.id}`}
              state={{ data: params.row }}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">Update</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">Reviews</div>
      {selectedRows.length > 0 ? (
        <button
          onClick={() => {
            handleDeleteSelectedRows();
          }}
        >
          Delete Selected Rows
        </button>
      ) : null}
      {openModal ? (
        <div className="modal">
          <div className="modalInner">
            <p className="closeModal" onClick={() => setOpenModal(false)}>
              &times;
            </p>
            <div style={{ margin: 40 }}>
              <img
                src={`https://darulsehatapi.dsh.boundlesstechnologies.net/reviews/${selectedRow.image}`}
                width={"400"}
                height={"400"}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {popUpShow ? (
        <PopupAlert popUpText={popUpText} backgroundColor={"red"} />
      ) : (
        ""
      )}
      {isLoading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={reviews}
          columns={actionColumn}
          checkboxSelection={true}
          onSelectionModelChange={(newSelection) => {
            setSelectedRows(newSelection);
          }}
          getRowId={(row) => {
            return row._id;
          }}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      )}
    </div>
  );
};

export default DatatableReviews;

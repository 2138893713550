import React from "react";
import ImageZoom from "react-image-zooom";
import { Table, Button } from "react-bootstrap";
import { GoPencil } from "react-icons/go";
import { useState } from "react";
import { useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import axios from "axios";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPharmacyOrders,
  fetchMedicines,
  setOrders,
} from "../../redux/pharmacySlice/PharmacySlice";

const DetailsModal = ({
  selectedRow,
  medicines,
  config,
  setPopupText,
  setPopupshow,
  setPopupColor,
  setOpenModal,
}) => {
  const [order, setOrder] = useState(selectedRow);
  const [medicine, setMedicine] = useState({});
  const [change, setChange] = useState(null);
  const [totalPrice, setTotalPrice] = useState(order.totalPrice);
  const [details, setDetails] = useState(order.details);
  const [qty, setQty] = useState(0);
  const [changeState, setChangeState] = useState(false);

  useEffect(() => {
    const totalGrossRate = details.reduce(
      (sum, detail) => sum + parseFloat(detail.gross_rate || 0),
      0
    );
    setTotalPrice(totalGrossRate);
  }, [details]);

  const dispatch = useDispatch();

  const handleChange = (index, sub_idesc, rn) => {
    setDetails((prevDetails) => {
      const gross_rate = parseFloat(medicine.unit_price) * qty;
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        sub_idesc: medicine.label,
        sub_icode: medicine.value,
        sub_idesc_old: medicine.label !== sub_idesc ? sub_idesc : null,
        rn_old: medicine.label !== sub_idesc ? rn : null,
        qty: qty,
        unit_price: medicine.unit_price,
        gross_rate: gross_rate,
      };

      return updatedDetails;
    });
    setQty(0);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { details: details, totalPrice: totalPrice };
    axios
      .patch(
        `https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/pharmacy-orders/update/${selectedRow._id}`,
        data,
        config
      )
      .then((response) => {
        setPopupshow(true);
        setChangeState(false);
        setPopupText("Order updated successfully");
        console.log(response.data);
        setPopupColor("orange");
        dispatch(fetchPharmacyOrders({ config }));
        setTimeout(() => {
          setPopupshow(false);
          setOpenModal(false);
          // window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div style={{ margin: 40 }}>
      <h3>User Details</h3>

      {order.user ? (
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td style={{ fontWeight: "bold" }}>Patient name</td>
              <td>{order.user?.name}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Contact No</td>
              <td>{order.user?.phoneNo}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "bold" }}>Address</td>
              <td>{order.full_address}</td>
            </tr>
          </tbody>
        </Table>
      ) : (
        ""
      )}
      <h3>Order Details</h3>
      <Table striped bordered hover>
        <tbody>
          <tr>
            <td style={{ fontWeight: "bold" }}>Status</td>
            <td>{order.status}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>Total Price</td>
            <td>{order.totalPrice}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>Payment Mode</td>
            <td>{order.payment_mode}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>Basket ID</td>
            <td>{order.basket_id ? order.basket_id : "-"}</td>
          </tr>
        </tbody>
      </Table>
      {/* {loading ? (
        <CircularProgress />
      ) : ( */}
      {details?.length > 0 && (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5>Details:</h5>
            <Button disabled={!changeState} onClick={handleSubmit}>
              Update
            </Button>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Product Id</th>
                <th>Product Desc</th>
                <th>Product Desc Old</th>
                <th>Quantity</th>
                <th>Unit Rate</th>
                <th>Gross Rate</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {details.map((item, index) => {
                let medicineOld = null;
                if (item.rn_old) {
                  medicineOld = medicines?.find(
                    (row) => row.rn === item.rn_old
                  );
                }
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.sub_icode}</td>
                    <td>
                      {change !== item.rn ? (
                        <div>
                          <p style={{ fontWeight: "bold", color: "black" }}>
                            {item.sub_idesc}
                          </p>
                        </div>
                      ) : (
                        <Select
                          options={medicines}
                          value={medicine}
                          onChange={(selectedOption) =>
                            setMedicine(selectedOption)
                          }
                        />
                      )}
                      <p style={{ color: "red", fontSize: 12 }}>
                        {item.alternative && !item.sub_idesc_old
                          ? "Please suggest an alternative"
                          : ""}
                      </p>
                    </td>
                    <td
                      style={{
                        textDecoration: item.sub_idesc_old
                          ? "line-through"
                          : "none",
                      }}
                    >
                      {item.sub_idesc_old ? item.sub_idesc_old : "-"}
                    </td>
                    <td>
                      {" "}
                      {change !== item.rn ? (
                        item.qty
                      ) : (
                        <input
                          type="number"
                          value={qty}
                          onChange={(e) => setQty(e.target.value)}
                        />
                      )}
                    </td>
                    <td>{item.unit_price}</td>
                    <td>{item.gross_rate}</td>
                    <td>
                      {item.alternative ? (
                        change !== item.rn ? (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              setChange(item.rn);
                              setQty(item.qty);
                              setMedicine({
                                value: item.rn,
                                label: item.sub_idesc,
                                ...item,
                              });
                            }}
                          >
                            <GoPencil />
                          </div>
                        ) : (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              setChange(null);
                              setChangeState(true);
                              handleChange(index, item.sub_idesc, item.rn);
                            }}
                          >
                            <FaCheck />
                          </div>
                        )
                      ) : (
                        <p style={{ color: "green", fontSize: 15 }}>
                          No alternative needed
                        </p>
                      )}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Total</td>
                <td style={{ fontWeight: "bold", color: "black" }}>
                  {totalPrice}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
      {/* )} */}

      {order.images.length > 0 ? (
        <div>
          <h5>Pharmacy Images: </h5>
          {order.images.map((row, index) => (
            <div
              key={index}
              onClick={() =>
                window.open(
                  `https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/pharmacy-orders/images/${row}`,
                  "_blank"
                )
              }
            >
              <ImageZoom
                src={`https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/pharmacy-orders/images/${row}`}
                alt={row}
                zoom="200"
                width="800"
                height="500"
                className="zoomImage"
              />
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DetailsModal;

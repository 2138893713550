import "../../style/list.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DatatableOrders from "./DatatableOrders";

const Orders = () => {
  return <DatatableOrders />;
};

export default Orders;

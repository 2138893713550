import "../../style/datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import axios from "axios";
import PopupAlert from "../../components/popupalert/popupAlert";
import { CircularProgress } from "@mui/material";
import DetailsModal from "./DetailsModal";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchPharmacyOrders,
  fetchMedicines,
  setOrders,
} from "../../redux/pharmacySlice/PharmacySlice";

const DatatableOrders = () => {
  // const [orders, setorders] = useState([]);
  const [popUpShow, setPopupshow] = useState(false);
  const [popUpText, setPopupText] = useState("");
  // const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  // const [medicines, setMedicines] = useState([]);
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const [popUpColor, setPopupColor] = useState("green");
  const [totalPrice, settotalPrice] = useState("");
  // const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  // const [show, setShow] = useState("");
  const options = [
    "Pending",
    "Processing",
    "On-the-way",
    "Delivered",
    "Cash Received",
    "Ready for Collection",
    "Completed",
    "Cancelled",
  ];

  const token = localStorage.getItem("dsh_token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const dispatch = useDispatch();
  const { orders, medicines, isLoading, loading } = useSelector(
    (state) => state.pharmacy
  );

  useEffect(() => {
    dispatch(fetchPharmacyOrders({ config }));
  }, [dispatch]);

  // useEffect(() => {
  // axios
  //   .get(
  //     "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/pharmacy-orders",
  //     config
  //   )
  //   .then((response) => {
  //     setorders(response.data);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   })
  //   .finally(() => {
  //     setIsLoading(false);
  //   });
  // }, [orders]);

  // useEffect(async () => {
  //   try {
  //     const response = await axios.get(
  //       "http://119.63.129.51:8000/api/items_rate_balance"
  //     );
  //     if (response.status === 200) {
  //       const typeArrange = response.data.itemsrates.map((row) => {
  //         return {
  //           value: row.rn,
  //           label: row.sub_idesc,
  //           unit_price: row.unit_price,
  //         };
  //       });
  //       setShow("Done");
  //       setLoading(false);
  //       setMedicines(typeArrange);
  //     }
  //   } catch (error) {
  //     setShow("Error");
  //     setLoading(false);
  //     console.log(error);
  //   }
  // }, [medicines]);

  const handleChangeStatus = (id, status) => {
    axios
      .patch(
        `https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/pharmacy-orders/change/${id}`,
        {
          status: status,
        },
        config
      )
      .then((response) => {
        setPopupText(`Order status changed to ${status}`);
        setPopupColor("orange");
        setPopupshow(true);
        setTimeout(() => {
          // window.location.reload();
          dispatch(fetchPharmacyOrders({ config }));
          setPopupshow(false);
        }, 1000);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
        } else {
          console.log(error);
        }
      });
  };

  const confirmOrder = (id) => {
    axios
      .patch(
        `https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/pharmacy-orders/confirm/${id}`,
        {
          totalPrice: totalPrice,
        },
        config
      )
      .then((response) => {
        setPopupText("Order Confirmed");
        setPopupColor("orange");
        setPopupshow(true);
        setTimeout(() => {
          // window.location.reload();
          dispatch(fetchPharmacyOrders({ config }));
          setPopupshow(false);
        }, 1000);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
        } else {
          console.log(error);
        }
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/pharmacy-orders/deletePharamcyOrder/" +
          id,
        config
      )
      .then((response) => {
        dispatch(setOrders(response.data));
        setPopupshow(true);
        setPopupText("Pharmacy Order Deleted");
        setPopupColor("red");

        setTimeout(() => {
          setPopupshow(false);
        }, 2000);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else {
          console.log(error);
        }
      });
  };

  const handleDeleteSelectedRows = () => {
    selectedRows.forEach((row) => {
      axios
        .delete(
          "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/pharmacy-orders/deletePharamcyOrder/" +
            row,
          config
        )
        .then((response) => {
          dispatch(setOrders(response.data));
          setPopupshow(true);
          setPopupText(`${selectedRows.length} Pharmacy Orders Deleted`);
          setPopupColor("red");
        });
    });
    setTimeout(() => {
      setPopupshow(false);
    }, 2000);
    setSelectedRows([]);
  };

  const actionColumn = [
    {
      field: "orderId",
      headerName: "Order Id",
      width: 150,
    },
    {
      field: "date",
      headerName: "Order date",
      width: 180,
      renderCell: (params) => {
        const createdAtDate = new Date(params.row.createdAt);

        const formattedDate = createdAtDate.toLocaleDateString();
        const formattedTime = createdAtDate.toLocaleTimeString();
        return (
          <div>
            {formattedDate} {formattedTime}
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Username",
      width: 200,
      valueGetter: (params) => {
        return params.row.user?.name;
      },
    },

    {
      field: "phoneNo",
      headerName: "Contact No.",
      width: 150,
      valueGetter: (params) => {
        return params.row.user?.phoneNo;
      },
    },
    {
      field: "full_address",
      headerName: "Address",
      width: 200,
    },
    {
      field: "totalPrice",
      headerName: "Price",
      width: 100,
    },
    {
      field: "payment_mode",
      headerName: "Payment method",
      width: 150,
    },
    {
      field: "basket_id",
      headerName: "Basket ID",
      width: 150,
      valueGetter: (params) => {
        return params.row.basket_id ? params.row.basket_id : "-";
      },
    },

    {
      field: "actionStatus",
      headerName: "Change Status",
      width: 200,
      renderCell: (params) => {
        return (
          <select
            value={params.row.status}
            onChange={(event) => {
              handleChangeStatus(params.row._id, event.target.value);
            }}
          >
            <option value="">Change Status</option>
            {options.map((row, index) => (
              <option value={row}>{row}</option>
            ))}
          </select>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {loading ? (
              <CircularProgress />
            ) : (
              <div
                className="viewButton"
                onClick={() => {
                  setOpenModal(true);
                  setSelectedRow(params.row);
                }}
              >
                View Details
              </div>
            )}
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle"> Pharmacy Orders</div>
      {selectedRows.length > 0 ? (
        <button
          onClick={() => {
            handleDeleteSelectedRows();
          }}
        >
          Delete Selected Rows
        </button>
      ) : null}
      {popUpShow ? (
        <PopupAlert popUpText={popUpText} backgroundColor={popUpColor} />
      ) : (
        ""
      )}

      {openModal ? (
        <div className="modal">
          <div className="modalInnerMedicine">
            <p
              className="closeModal"
              onClick={() => {
                setOpenModal(false);
                setSelectedRow([]);
              }}
            >
              X
            </p>
            <DetailsModal
              setOpenModal={setOpenModal}
              selectedRow={selectedRow}
              medicines={medicines}
              config={config}
              setPopupText={setPopupText}
              setPopupColor={setPopupColor}
              setPopupshow={setPopupshow}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {openPriceModal ? (
        <div className="modal">
          <div className="modalInner">
            <p
              className="closeModal"
              onClick={() => {
                setOpenPriceModal(false);
                setSelectedRow([]);
              }}
            >
              X
            </p>
            <div style={{ margin: 40 }}>
              <form
                onSubmit={() => confirmOrder(selectedRow._id)}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: 120,
                }}
              >
                <h5>Enter total Price</h5>
                <input
                  type="text"
                  value={totalPrice}
                  onChange={(e) => {
                    settotalPrice(e.target.value);
                  }}
                />
                <button className="viewButton">Confirm</button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {isLoading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={orders}
          getRowHeight={(params) => "auto"}
          columns={actionColumn}
          checkboxSelection={true}
          onSelectionModelChange={(newSelection) => {
            setSelectedRows(newSelection);
          }}
          getRowId={(row) => {
            return row._id;
          }}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      )}
    </div>
  );
};

export default DatatableOrders;

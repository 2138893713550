import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

const NewMember = ({
  setOpenModal,
  setPopupColor,
  setPopupText,
  setPopupshow,
}) => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [contact_no, setContactNo] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");

  const token = localStorage.getItem("dsh_token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: name,
      username: username,
      email: email,
      role: role,
      password: password,
      contact_no: contact_no,
    };

    axios
      .post(`https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/members`, data, config)
      .then((response) => {
        setOpenModal(false);
        setPopupshow(true);
        setPopupColor("green");
        setPopupText(`New ${role} staff added`);
        setTimeout(() => {
          setPopupshow(false);
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data) {
          alert(error.response.data);
        }
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="formName">
        <Form.Label>Username</Form.Label>
        <Form.Control
          type="text"
          placeholder="Username"
          required
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="formName">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="formName">
        <Form.Label>Contact No</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Contact No"
          value={contact_no}
          onChange={(e) => setContactNo(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="formName">
        <Form.Label>Role</Form.Label>
        <Form.Control
          as="select"
          value={role}
          onChange={(e) => {
            setRole(e.target.value);
          }}
          required
        >
          <option value="">Select role</option>
          <option value="pharmacy">Pharmacy</option>
          <option value="lab">Lab</option>
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="formName">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="text"
          required
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>

      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};
export default NewMember;

import "../../style/datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import ViewDetails from "./ViewDetails";
import SureModal from "../../components/SureModel/SureModal";
import PopupAlert from "../../components/popupalert/popupAlert";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLabOrders,
  setOrders,
} from "../../redux/labOrderSlice/LabOrderSlice";

const DatatableLabOrders = () => {
  const [popUpShow, setPopupshow] = useState(false);
  const [popUpText, setPopupText] = useState("");
  const [popUpColor, setPopupColor] = useState("");
  // const [orders, setorders] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const options = [
    "Pending",
    "Processing",
    "On-the-way",
    "In-Process",
    "Completed",
    "Cancelled",
  ];

  const token = localStorage.getItem("dsh_token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const dispatch = useDispatch();
  const { orders, isLoading } = useSelector((state) => state.labOrders);

  useEffect(() => {
    dispatch(fetchLabOrders({ config }));
  }, [dispatch]);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/lab-orders`,
  //       config
  //     )
  //     .then((response) => {
  //       setorders(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // }, [orders]);

  const handleChangeStatus = (id, status) => {
    axios
      .patch(
        `https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/lab-orders/change/${id}`,
        { status: status },
        config
      )
      .then((response) => {
        setPopupText(`Order status changed to ${status}`);
        setPopupColor("green");
        setPopupshow(true);
        setTimeout(() => {
          // window.location.reload();
          dispatch(fetchLabOrders({ config }));
          setPopupshow(false);
        }, 1000);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
        } else {
          console.log(error);
        }
      });
  };

  const handleDelete = (id) => {
    try {
      axios
        .delete(
          "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/lab-orders/deleteLabOrder/" +
            id,
          config
        )
        .then((response) => {
          dispatch(setOrders(response.data));
        });
      setPopupshow(true);
      setPopupText("Lab Order Deleted");
      setPopupColor("red");
      setTimeout(() => {
        setPopupshow(false);
      }, 100);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      } else {
        console.log(error);
      }
    }
  };

  const handleDeleteSelectedRows = () => {
    selectedRows.forEach((row) => {
      axios
        .delete(
          "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/lab-orders/deleteLabOrder/" +
            row,
          config
        )
        .then((response) => {
          dispatch(setOrders(response.data));
          setPopupColor("red");
          setPopupshow(true);
          setPopupText(`${selectedRows.length} Lab Orders Deleted`);
        });
    });
    setTimeout(() => {
      setPopupshow(false);
    }, 2000);
    setSelectedRows([]);
  };

  const actionColumn = [
    {
      field: "orderId",
      headerName: "Order Id",
      width: 100,
    },
    {
      field: "date",
      headerName: "Order date",
      width: 180,
      renderCell: (params) => {
        const createdAtDate = new Date(params.row.dateOrdered);

        const formattedDate = createdAtDate.toLocaleDateString();
        const formattedTime = createdAtDate.toLocaleTimeString();
        return (
          <div>
            {formattedDate} {formattedTime}
          </div>
        );
      },
    },
    {
      field: "userName",
      headerName: "Patient name",
      width: 150,
      valueGetter: (params) => params.row.patientDetails?.name,
    },
    {
      field: "userPhoneNo",
      headerName: "Contact No",
      width: 120,
      valueGetter: (params) => params.row.user?.phoneNo,
    },
    {
      field: "full_address",
      headerName: "Address",
      width: 200,
    },
    {
      field: "totalPrice",
      headerName: "Price",
      width: 100,
    },
    {
      field: "payment_mode",
      headerName: "Payment method",
      width: 150,
    },
    {
      field: "basket_id",
      headerName: "Basket ID",
      width: 150,
      valueGetter: (params) => {
        return params.row.basket_id ? params.row.basket_id : "-";
      },
    },

    {
      field: "actionStatus",
      headerName: "Change Status",
      width: 150,
      renderCell: (params) => {
        return (
          <select
            value={params.row.status}
            onChange={(event) => {
              handleChangeStatus(params.row._id, event.target.value);
            }}
          >
            <option value="">Change Status</option>
            {options.map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </select>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => {
                setOpenModal(true);
                setSelectedRow(params.row);
              }}
            >
              View Details
            </div>
            {/* {params.row.status === "Completed" ||
            params.row.status === "Cancelled" ? (
              ""
            ) : (
              <div
                className="viewButton"
                onClick={() => {
                  setUploadModal(true);
                  setSelectedRow(params.row);
                }}
              >
                Upload result
              </div>
            )} */}
            <div
              className="deleteButton"
              onClick={() => {
                setDeleteModal(true);
                setSelectedRow(params.row);
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle"> Lab Orders</div>
      {selectedRows.length > 0 ? (
        <button
          onClick={() => {
            handleDeleteSelectedRows();
          }}
        >
          Delete Selected Rows
        </button>
      ) : null}
      {popUpShow ? (
        <PopupAlert popUpText={popUpText} backgroundColor={popUpColor} />
      ) : (
        ""
      )}
      {openModal ? (
        <div className="modal">
          <div className="modalInnerMedicine">
            <p
              className="closeModal"
              onClick={() => {
                setOpenModal(false);
                setSelectedRow([]);
              }}
            >
              X
            </p>
            <ViewDetails
              setOpenModal={setOpenModal}
              selectedRow={selectedRow}
              setPopupText={setPopupText}
              setPopupshow={setPopupshow}
              setPopupColor={setPopupColor}
              config={config}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {/* Upload modal
      {uploadModal ? (
        <div className="modal">
          <div className="modalInner" style={{ padding: "50px" }}>
            <p
              className="closeModal"
              onClick={() => {
                setUploadModal(false);
                setSelectedRow([]);
              }}
            >
              X
            </p>

            <UploadResult selectedRow={selectedRow._id} />
          </div>
        </div>
      ) : (
        ""
      )} */}
      {/* Upload modal */}
      {deleteModal ? (
        <div className="modal">
          <div className="modalInner" style={{ padding: "50px" }}>
            <p
              className="closeModal"
              onClick={() => {
                setDeleteModal(false);
                setSelectedRow([]);
              }}
            >
              X
            </p>

            <SureModal
              config={config}
              url={`https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/lab-orders/deleteLabOrder/`}
              setUpClose={setDeleteModal}
              ids={[selectedRow._id]}
              setPopupshow={setPopupshow}
              setPopupColor={setPopupColor}
              setPopupText={setPopupText}
              name={"Lab Order"}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {isLoading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={orders}
          columns={actionColumn}
          checkboxSelection={true}
          onSelectionModelChange={(newSelection) => {
            setSelectedRows(newSelection);
          }}
          getRowId={(row) => {
            return row._id;
          }}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      )}
    </div>
  );
};

export default DatatableLabOrders;

import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

const UpdateMember = ({
  selectedRow,
  setOpenModal,
  setPopupColor,
  setPopupText,
  setPopupshow,
}) => {
  const [name, setName] = useState(selectedRow.name);
  const [username, setUsername] = useState(selectedRow.username);
  const [email, setEmail] = useState(selectedRow.email);
  const [contact_no, setContactNo] = useState(selectedRow.contact_no);
  const [role, setRole] = useState(selectedRow.role);

  const token = localStorage.getItem("dsh_token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: name,
      username: username,
      email: email,
      role: role,
      contact_no: contact_no,
    };

    axios
      .patch(
        `https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/members/change/${selectedRow._id}`,
        data,
        config
      )
      .then((response) => {
        setOpenModal(false);
        setPopupshow(true);
        setPopupColor("orange");
        setPopupText(`Staff updated`);
        setTimeout(() => {
          setPopupshow(false);
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data) {
          alert(error.response.data);
        }
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="formName">
        <Form.Label>Username</Form.Label>
        <Form.Control
          type="text"
          placeholder="Username"
          required
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="formName">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="formName">
        <Form.Label>Contact No</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Contact No"
          value={email}
          onChange={(e) => setContactNo(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="formName">
        <Form.Label>Role</Form.Label>
        <Form.Control
          as="select"
          value={role}
          onChange={(e) => {
            setRole(e.target.value);
          }}
        >
          <option value="">Select role</option>
          <option value="pharmacy">Pharmacy</option>
          <option value="lab">Lab</option>
        </Form.Control>
      </Form.Group>

      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};
export default UpdateMember;

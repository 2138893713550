import "../../style/list.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DatatableReviews from "./DatatableReviews";

const Reviews = () => {
  return <DatatableReviews />;
};

export default Reviews;

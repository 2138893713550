import React from "react";
import "../../style/list.scss";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import DatatableBookings from "./DatatableBookings";

const Bookings = () => {
  return <DatatableBookings />;
};

export default Bookings;

import "../../style/new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PopupAlert from "../../components/popupalert/popupAlert";
import { Rating } from "@mui/material";

const UpdateReview = ({ title }) => {
  const { id } = useParams();
  const location = useLocation();
  const data = location.state.data;
  const [review, setReview] = useState(data.review);
  const [popUpShow, setPopupshow] = useState(false);
  const [popUpText, setPopupText] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [rating, setRating] = useState(data.rating);
  const navigate = useNavigate();

  const token = localStorage.getItem("dsh_token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    const route = {
      rating: rating,
      review: review,
    };

    axios
      .patch(
        `https://studyapi.doctorsforhealth.co.uk/api/v1/reviews/${id}`,
        route,
        config
      )
      .then((res) => {
        setPopupshow(true);
        setPopupText("Review Update");
        setTimeout(() => {
          setPopupshow(false);
          navigate("/reviews");
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="top-new">
        <h1 className="heading-top">{title}</h1>
      </div>
      {popUpShow ? (
        <PopupAlert popUpText={popUpText} backgroundColor={"#8AFF8A"} />
      ) : (
        ""
      )}
      <div className="bottom">
        <div className="right">
          {errorMessage ? (
            <div style={{ color: "red", fontSize: 10 }}>
              Review already exists
            </div>
          ) : null}
          <form className="form-new" onSubmit={handleUpdate}>
            <div className="formInput">
              <label className="label-form">Review</label>
              <input
                type="text"
                className="input-form"
                value={review}
                onChange={(e) => setReview(e.target.value)}
              />
              <label className="label-form">Review</label>
              <Rating
                value={rating}
                precision={0.5}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
              />

              <button className="createButton">Update</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateReview;

import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import DatatableMedicines from "./DatatableMedicines";

const Medicines = () => {
  return <DatatableMedicines />;
};

export default Medicines;

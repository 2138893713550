import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

const Password = ({
  selectedRow,
  setOpenModal,
  setPopupColor,
  setPopupText,
  setPopupshow,
}) => {
  const [password, setPassword] = useState("");

  const token = localStorage.getItem("dsh_token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      password: password,
    };

    axios
      .patch(
        `https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/members/change/${selectedRow._id}`,
        data,
        config
      )
      .then((response) => {
        setOpenModal(false);
        setPopupshow(true);
        setPopupColor("orange");
        setPopupText(`Password updated`);
        setTimeout(() => {
          setPopupshow(false);
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data) {
          alert(error.response.data);
        }
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formName">
        <Form.Label>New password</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>

      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};
export default Password;

import React from "react";
import Login from "./pages/Login/Login";
import Adminroutes from "./routes/adminroutes";


function App() {
  const dsh_Id = localStorage.getItem("dsh_Id");
  return <div className="app">{dsh_Id ? <Adminroutes /> : <Login />}</div>;
}

export default App;

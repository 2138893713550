import "../../style/list.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DatatableLabOrders from "./DatatableLabOrders";
import Button from "react-bootstrap/Button";
import PopupAlert from "../../components/popupalert/popupAlert";
import { useState } from "react";

const LabOrders = () => {
  const [option, setOption] = useState("New-Order");
  const options = [
    "New-Order",
    "Pending",
    "On-the-way",
    "In-Process",
    "Completed",
    "Cancelled",
  ];
  return <DatatableLabOrders />;
};

export default LabOrders;

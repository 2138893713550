import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import DatatableLabTests from "./DatatableLabTests";

const LabTests = () => {
  return <DatatableLabTests />;
};

export default LabTests;

import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/home/Home";
import Patients from "../pages/Patients/Patients";
import Reviews from "../pages/Reviews/Reviews";
import UpdateReview from "../pages/Reviews/UpdateReview";
import Orders from "../pages/Orders/Orders";
import Bookings from "../pages/Bookings/Bookings";
import LabOrders from "../pages/LabOrders/LabOrders";
import UpdateAdmin from "../pages/UpdateAdmin/UpdateAdmin";
import Doctors from "../pages/Doctors/Doctors";
import Members from "../pages/Members/Members";
import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar";
import UsersRequests from "../pages/UsersDeleteRequests/UsersRequests";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchPharmacyOrders,
  fetchMedicines,
} from "../redux/pharmacySlice/PharmacySlice";
import { fetchLabOrders, fetchLabTests } from "../redux/labOrderSlice/LabOrderSlice";
import Medicines from "../pages/Medicines/Medicines";
import LabTests from "../pages/LabTests/LabTests";

function Adminroutes() {
  const dsh_Id = localStorage.getItem("dsh_Id");
  const role = localStorage.getItem("dsh_role");

  const token = localStorage.getItem("dsh_token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMedicines({ config }));
    dispatch(fetchLabTests({ config }));
  }, [dispatch]);

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <Routes>
          {dsh_Id ? (
            <>
              {role === "admin" ? (
                <>
                  <Route path="/" element={<Navigate to="/dashboard" />} />
                  <Route path="/dashboard" element={<Home />} />
                  <Route path="patients" element={<Patients />} />
                  <Route path="/doctors" element={<Doctors />} />
                  <Route path="/members" element={<Members />} />
                  <Route path="/delete-requests" element={<UsersRequests />} />

                  <Route path="reviews" element={<Reviews />} />

                  <Route
                    path="reviews/update/:id"
                    element={<UpdateReview title="Update Review" />}
                  />

                  <Route path="appointments" element={<Bookings />} />
                  <Route path="update" element={<UpdateAdmin />} />
                </>
              ) : (
                ""
              )}
              {role === "admin" || role === "pharmacy" ? (
                <>
                  <Route
                    path="/"
                    element={<Navigate to="/pharmacy-orders" />}
                  />
                  <Route path="medicines" element={<Medicines />} />
                  <Route path="pharmacy-orders" element={<Orders />} />
                  <Route path="update" element={<UpdateAdmin />} />
                </>
              ) : (
                ""
              )}
              {role === "admin" || role === "lab" ? (
                <>
                  <Route path="/" element={<Navigate to="/lab-orders" />} />
                  <Route path="lab-tests" element={<LabTests />} />
                  <Route path="lab-orders" element={<LabOrders />} />
                  <Route path="update" element={<UpdateAdmin />} />
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <Route path="/" element={<Home />} />
          )}
        </Routes>
      </div>
    </div>
  );
}

export default Adminroutes;

import "../../style/datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const DatatableLabTests = () => {
  // const [tests, setTests] = useState([]);
  // const [loading, setLoading] = useState(true);

  const { tests, loading } = useSelector((state) => state.labOrders);

  // useEffect(() => {
  //   axios
  //     .get("http://119.63.129.51:8000/api/lab_data")
  //     .then((response) => {
  //       if (response.data) {
  //         setTests(response.data.lab_datas);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       console.log(error);
  //     });
  // }, []);

  const actionColumn = [
    {
      field: "rn",
      headerName: "Value",
      width: 100,
    },
    {
      field: "group_serv_desc",
      headerName: "Name",
      width: 400,
    },
    {
      field: "private_rate",
      headerName: "Rate",
      width: 200,
    },
    {
      field: "due_date",
      headerName: "Due Date",
      width: 300,
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">Lab Tests</div>

      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={tests}
          columns={actionColumn}
          getRowId={(row, index) => {
            return row.rn;
          }}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      )}
    </div>
  );
};

export default DatatableLabTests;
